<template>
  <div class="relative">
    
    <div v-if="orderData.failure_data.length > 0" :class="gotVerifyAlert || $route.params.type === 'cash-collections' ? '' : 'verify-bg'"></div>
    <order-view-breadcrumb v-if="$store.state.AppActiveUser.userRole !== 'finance' && $route.name === `${$store.state.AppActiveUser.userRole}-${$route.params.type}`" :orderData="orderData"/>
    
    <!-- TRACKING INFO -->
    <tracking-info class="mb-4" :orderData="orderData" 
      :ringerHistory="ringerHistory" :trackingCardLoading="trackingCardLoading" :warehouses="warehouses" :trackings="trackings" 
      @loadOrder="loadOrder" @copied="copied" @loadLogs="loadLogs"></tracking-info>

    <div v-if="$route.params.type !== 'cash-collections'">
      <div class="grid xl:grid-cols-4 lg:grid-cols-5 grid-cols-3 gap-4">
        <div :class="$store.state.AppActiveUser.userRole !== 'head-of-fleet' && $store.state.AppActiveUser.userRole !== 'manager' ? 'col-span-3 grid grid-cols-4 gap-4' : 'xl:col-span-4 lg:col-span-5 col-span-3 grid grid-cols-4 gap-4'">
          <!-- NOTES & REFERENCE NUMBER -->
          <notes-and-reference-number :merchantStatus="merchantStatus" class="col-span-4" :orderData="orderData" :notesCardLoading="notesCardLoading" @loadOrder="loadOrder"></notes-and-reference-number>
          <!-- CUSTOMER INFO -->
          <customer-info :merchantStatus="merchantStatus" :class="orderData.failure_data.length > 0 ? 'xl:col-span-2 col-span-4' : 'col-span-4'" :orderData="orderData" :customerInfoCardLoading="customerInfoCardLoading" @loadOrder="loadOrder" @copied="copied"></customer-info>
          <!-- FAILURE REASONS -->
          <failure-reasons :class="gotVerifyAlert ? '' : 'verify-style'" class="xl:col-span-2 col-span-4" v-if="orderData.failure_data.length > 0" :trackingCardLoading="trackingCardLoading" :attemptsValidation="attemptsValidation" @gotVerifyAlert="gotVerifyAlert = $event" :gotVerifyAlert="gotVerifyAlert" :trackings="trackings"></failure-reasons>
        </div>
        <!-- ORDER PRICE -->
        <order-price v-if="$store.state.AppActiveUser.userRole !== 'head-of-fleet' && $store.state.AppActiveUser.userRole !== 'manager'" :merchantStatus="merchantStatus" class="xl:col-span-1 lg:col-span-2 col-span-3" :orderData="orderData" :priceCardLoading="priceCardLoading" @loadOrder="loadOrder"></order-price>
      </div>
      <div class="mt-4 grid lg:grid-cols-2 grid-cols-1 gap-4">
        <!-- ORDER INFO -->
        <order-info :merchantStatus="merchantStatus" :orderData="orderData" :orderInfoCardLoading="orderInfoCardLoading" :isCounterDropOff="isCounterDropOff" @loadOrder="loadOrder" :insurance="insurance"></order-info>
        <!-- PACKAGE INFO -->
        <package-info :merchantStatus="merchantStatus" :orderData="orderData" :packageInfoCardLoading="packageInfoCardLoading" @loadOrder="loadOrder"></package-info>
      </div>
      <!-- DELIVERY ORDER PRODUCTS -->
      <delivery-order-products class="mt-4" :orderData="orderData" @loadOrder="loadOrder" :fulfillingCardLoading="fulfillingCardLoading" :fulfillment="fulfillment" :merchantID="orderData.merchant.id"
        v-if="fulfillmentSubscription || ($store.state.AppActiveUser.userRole !== 'merchant' && fulfillment.products.length > 0 && $store.state.AppActiveUser.userRole !== 'finance')"></delivery-order-products>
    </div>

    <div v-else class="grid xl:grid-cols-5 grid-cols-3 gap-4">
      <!-- CUSTOMER INFO -->
      <customer-info class="xl:col-span-3 lg:col-span-2 col-span-3" :orderData="orderData" :customerInfoCardLoading="customerInfoCardLoading" @loadOrder="loadOrder" @copied="copied"></customer-info>
      <!-- ORDER PRICE -->
      <order-price v-if="$store.state.AppActiveUser.userRole !== 'head-of-fleet' && $store.state.AppActiveUser.userRole !== 'manager'" class="xl:col-span-2 lg:col-span-1 col-span-3" :orderData="orderData" :priceCardLoading="priceCardLoading" @loadOrder="loadOrder"></order-price>
      <!-- ORDER INFO -->
      <order-info class="xl:col-span-3 lg:col-span-2 col-span-3" :orderData="orderData" :orderInfoCardLoading="orderInfoCardLoading" :isCounterDropOff="isCounterDropOff" @loadOrder="loadOrder"></order-info>
    </div>

  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import {sendRequest, sendFulfillmentRequest} from '@/http/axios/requestHelper.js'
import TrackingInfo from './TrackingInfo.vue'
import NotesAndReferenceNumber from './NotesAndReferenceNumber.vue'
import CustomerInfo from './CustomerInfo.vue'
import FailureReasons from './FailureReasons.vue'
import OrderPrice from './OrderPrice.vue'
import OrderInfo from './OrderInfo.vue'
import PackageInfo from './PackageInfo.vue'
import DeliveryOrderProducts from '@/views/merchant/order-view/DeliveryOrderProducts.vue'
import i18nData from '@/i18n/i18nData.js'
import common from '../../../assets/utils/common'
import OrderViewBreadcrumb from './OrderViewBreadcrumb.vue'

export default {
  data () {
    return {
      orderURL: '',
      fulfillmentSubscription: false,
      fulfillmentOrderId: '',
      orderData: {
        linked_exchange_order: {
          delivery_order: {},
          return_order: {}
        },
        visit_dates: [],
        request_price: {
          total: 0,
          subtotal: 0,
          vat: 0,
          postal_tax: 0
        },
        failure_data: [],
        merchant: {
          subscription: {}
        },
        customer: {
          address: {
            zone: {
              city: {
                governorate: {}
              }
            }
          }
        },
        created: '',
        packages: [],
        preferred_window:[],
        pickup_point: {},
        order_price: {
          total: 0,
          subtotal: 0,
          vat: 0,
          postal_tax: 0
        }
      },
      fulfillment: {
        products: [],
        flags: []
      },
      trackings: [],
      isCounterDropOff: false,
      warehouses: [],
      ringerHistory: [],
      trackingCardLoading: false,
      notesCardLoading: false,
      priceCardLoading: false,
      customerInfoCardLoading: false,
      orderInfoCardLoading: false,
      packageInfoCardLoading: false,
      fulfillingCardLoading: false,
      gotVerifyAlert: false,
      attemptsValidation: [],
      insurance: {},
      merchantStatus: ''
    }
  },
  watch: {
    '$route.params.type' () {
      this.orderURL = `api/v1/${this.$route.params.type}/`
      this.loadOrder()
    }
  },
  methods: {
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
        this.$vs.notify({
          color:'success',
          title:i18nData[this.$i18n.locale]['copied to clipboard!'],
          position: 'bottom-center'
        })
      } catch ($e) {
        this.$vs.notify({
          color:'danger',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    },
    loadOrder (cardName) {
      if (cardName) {
        this[cardName] = true
      } else {
        this.trackingCardLoading = true
        this.notesCardLoading = true
        this.priceCardLoading = true
        this.customerInfoCardLoading = true
        this.orderInfoCardLoading = true
        this.packageInfoCardLoading = true
        this.fulfillingCardLoading = true
      }
      sendRequest(true, false, this, `${this.orderURL}${this.$route.params.orderID}/`, 'get', null, true, 
        (response) => {
          this.orderData = response.data
          this.isCounterDropOff = this.orderData.is_counter_dropoff
          if (this.orderData.merchant_insurance_package) {
            this.insurance = this.orderData.merchant_insurance_package
          }
          this.packagesCount = this.orderData.packages ? this.orderData.packages.length : 0
          if (this.orderData.fulfillment_provider_metadata) {
            this.fulfillmentOrderId = this.orderData.fulfillment_provider_metadata.shipblu.order
            this.loadFulfillmentOrder()
          }
          if (this.$route.params.type === 'delivery-orders' || this.$route.params.type === 'returns') {
            this.orderData.pickup_point = `${this.orderData.pickup_point.address.line_1}, ${this.orderData.pickup_point.address.line_2} (${i18nData[this.$i18n.locale][this.orderData.pickup_point.address.zone.name] ? i18nData[this.$i18n.locale][this.orderData.pickup_point.address.zone.name] : this.orderData.pickup_point.address.zone.name})`
            this.orderData.return_point = this.orderData.return_point ? `${this.orderData.return_point.address.line_1}, ${this.orderData.return_point.address.line_2} (${i18nData[this.$i18n.locale][this.orderData.return_point.address.zone.name] ? i18nData[this.$i18n.locale][this.orderData.return_point.address.zone.name] : this.orderData.return_point.address.zone.name})` : ''
            this.orderData.cash = this.orderData.cash_amount
            this.orderData.cash_amount = this.orderData.cash_amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})
            this.orderData.declared_value = this.orderData.declared_value ? this.orderData.declared_value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0
            if (this.$store.state.AppActiveUser.userRole === 'merchant' && localStorage.getItem('is_fulfillment') && localStorage.getItem('is_fulfillment') === 'true') {
              this.loadMerchantFulfillment()
            }
          }
          if (cardName) {
            this[cardName] = false
          } else {
            if (!this.orderData.fulfillment_provider_metadata) {
              this.fulfillingCardLoading = false
            }
            this.trackingCardLoading = false
            this.notesCardLoading = false
            this.customerInfoCardLoading = false
            this.orderInfoCardLoading = false
            this.packageInfoCardLoading = false
            this.priceCardLoading = false
          }
          this.trackings = response.data.tracking_events.reverse()
          if (this.trackings.filter(item => item.status === 'delivery_attempted').length > 0) {
            this.loadAttemptsValidation()
          }
          if (this.$store.state.AppActiveUser.userRole !== 'finance' && this.$route.params.type === 'cash-collections') {
            this.loadTrackingInfo()
          }
          if (this.$store.state.AppActiveUser.userRole === 'admin' || this.$store.state.AppActiveUser.userRole === 'customer-support' || this.$store.state.AppActiveUser.userRole === 'manager' || this.$store.state.AppActiveUser.userRole === 'head-of-fleet' || this.$store.state.AppActiveUser.userRole === 'fc-operations-supervisor') {
            this.loadRingerHistory()
          }
          this.orderData.failure_data = this.orderData.tracking_events.filter(item => item.comment)
        }
      )
    },
    loadRingerHistory () {
      sendRequest(true, false, this, `api/v1/ringer/agent-customer-call/?callee=${this.orderData.customer.id}`, 'get', null, true, 
        response => {
          this.ringerHistory = response.data.results
        }
      )
    },
    loadTrackingInfo () {
      sendRequest(true, false, this, `api/v1/tracking/cash-collections/?limit=50&cash_collection_request=${this.$route.params.orderID}`, 'get', null, true, 
        response => {
          this.trackings = response.data.results
        }
      )
    },
    loadAttemptsValidation () {
      sendRequest(true, false, this, `api/v1/ringer/order-failure-validation/?attempt__order__tracking_number=${this.orderData.tracking_number}`, 'get', null, true, 
        response => {
          this.attemptsValidation = response.data.results
        }
      )
    },
    loadMerchantFulfillment () {
      sendFulfillmentRequest(true, false, this, `api/v1/fc/merchants/${this.orderData.merchant.id}/`, 'get', null, true,
        () => {
          this.fulfillmentSubscription = true
        }, () => {}
      )
    },
    loadFulfillmentOrder () {
      const url = this.$route.params.type === 'returns' ? 'api/v1/fc/orders/inbound' : 'api/v1/fc/orders/outbound'
      sendFulfillmentRequest(true, false, this, `${url}/${this.fulfillmentOrderId}/`, 'get', null, true,
        (response) => {
          this.fulfillment = response.data
          this.fulfillment.products.map(item => { item.product.optionalLabel = `SKU: ${item.product.sku} . ${item.product.current_inventory_level} available` })
          this.fulfillingCardLoading = false
        }, () => {}
      )
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(element => {
        if (!element.is_virtual) {
          this.warehouses.push(element)
        }
      })
    },
    loadLogs () {
      const query = `?limit=100&from=${this.orderData.created.split('T')[0]} ${this.orderData.created.split('T')[1].split('.')[0]}`
      sendRequest(true, false, this, `api/v1/${this.$route.params.type === 'cash-collections' ? 'cash-collection' : 'delivery-orders'}/${this.orderData.id}/logs/${query}`, 'get', null, true,
        (response) => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:response.data.message,
            position: 'top-center'
          })        
        }
      )
    }
  },
  components: {
    ShipbluCard,
    TrackingInfo,
    NotesAndReferenceNumber,
    CustomerInfo,
    FailureReasons,
    OrderPrice,
    OrderInfo,
    PackageInfo,
    DeliveryOrderProducts,
    OrderViewBreadcrumb
  },
  mounted () {
    this.gotVerifyAlert = (this.$cookies.get('gotVerifyAlert') === 'true')
  },
  created () {
    this.gotVerifyAlert = (this.$cookies.get('gotVerifyAlert') === 'true')
    if (this.$store.state.AppActiveUser.userRole !== 'merchant' && this.$store.state.AppActiveUser.userRole !== 'customer-support' && this.$store.state.AppActiveUser.userRole !== 'sales') {
      common.manageLoadWarehouses(this.formatWarehouses, this)
    }
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      let user = {}
      merchantData.then(results => {
        user = results.merchantData
        this.merchantStatus = user.status
      })
    }
    this.orderURL = `api/v1/${this.$route.params.type}/`
    this.loadOrder()
  }
}
</script>

<style lang="scss">
.verify-bg {
  position: fixed;
  inset: 0;
  z-index: 99999;
  background: rgba(112, 112, 112, 0.28);
  backdrop-filter: blur(4px);
}
.verify-style {
  z-index: 99999;
  border: 1.5px solid #1C5BFE;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  .verify-alert {
    position: absolute;
    bottom: calc(100% + 25px);
    right: 0;
    width: 555px;
    max-width: calc(100vw - 70px);
    background: #FFFFFF;
    border: 1.5px solid #1C5BFE;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    padding: 15px 15px 7px 15px;
     &:before {
      content: "";
      position: absolute;
      bottom: -9px;
      right: 50px;
      height: 15px;
      width: 15px;
      background: #FFFFFF;
      border: solid #1C5BFE;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
      pointer-events: none;
    }
  }
}
</style>